import React, { useState } from 'react';
import { CreabekLink } from '@components';
import { Api } from '@utils';
import { People, EmojiSmileFill, HourglassSplit, BookmarkStar, XCircle,Eye,EyeSlash } from 'react-bootstrap-icons';

function GameAdmin({ game }) 
{
    const [eyeIcone, setEyeIcone] = useState(getEye());
    function getEye()
    {
       return game.Visibled ? <Eye role="button" data-toggle="tooltip" data-placement="bottom" title="Masquer ce prototype" onClick={() => VisibilityChanged()}/> : <EyeSlash role="button" data-toggle="tooltip" data-placement="bottom" title="Afficher ce prototype" onClick={() => VisibilityChanged()}/>
    }
    const VisibilityChanged = async () => {
        await Api.Games.ChangeVisibility(game);
        setEyeIcone(getEye());
      };
    return (
        <>
            {eyeIcone}

        </>
    );
}

function GameMechanics({ mechanicsList,startKey }) {
    return (
        <>
            {mechanicsList.map((m) => (
                <div className="col mb-2" key={startKey+"-"+m.Value}>
                    <span className="badge">
                        <BookmarkStar /> {m.Value}
                    </span>
                </div>
            ))}
        </>


    );
}



function Game({ game, mechanicsList, myGame = false }) {
    var linkData = {
        web: game.Web,
        title: game.C_Name,
        classes: "",
        icones: null,
        isInternal: false

    };
    return (
        <>
            <li className="list-group-item bg-light">
                <div className="d-md-flex justify-content-between align-items-center">
                    <div>{game.G_Name} - <CreabekLink linkData={linkData} /></div>
                    <button type="button" className="btn btn-primary mt-1 mt-md-0" data-toggle="modal" data-target={"#G-" + game.G_ID}>
                        Voir les informations du prototype
                    </button>
                    {myGame ? (<GameAdmin game={game} />) : <></>}
                </div>
            </li>
            <GameModal game={game} mechanicsList={mechanicsList} />
        </>


    );
}

function GameModal({ game, mechanicsList }) {

    return (

        <div className="modal fade" id={"G-" + game.G_ID} data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{game.G_Name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><XCircle/></span>
                        </button>
                    </div>
                    <div className="modal-body py-4">
                        <div className="px-3 pb-4">
                            <h3>Description du jeu</h3>
                            <p dangerouslySetInnerHTML={{ __html: game.Description }} />
                        </div>

                        <div className="row px-3">
                            <div className="col-md">
                                <small className="text-muted text-center mb-1"> Nombre de joueurs</small>
                                <p>{game.NBPlayer} <People /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Durée</small>
                                <p>{game.Duration} <HourglassSplit /></p>
                            </div>
                            <div className="w-100"/>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Âge minimum</small>
                                <p>{game.Age} <EmojiSmileFill /></p>
                            </div>
                            <div className="col-md">
                                <small className="text-muted text-center mb-1">Méchanique du jeu</small>
                                <div className="row"><GameMechanics mechanicsList={mechanicsList} startKey={"M-"+game.G_ID} /></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

// function GameImages() {
//     // listReactFiles('./').then(files => console.log(files))
//     //const fileList = fs.readdirSync('./G-1');
//     return (

//         <div class="photo-gallery">
//             <div class="container">
//                 <div class="row photos">
//                     {/* {files.map((f) => (

//                      <div class="col-sm-6 col-md-4 col-lg-3 item">
//                      <a href={"./src/" + f} data-lightbox="photos">
//                           <img class="img-fluid" style="max-width:250px;max-height:250px;" src={"./src/" + f}/>
//                       </a>
//                   </div>

//                        ))} */}

//                 </div>
//             </div>
//         </div>
//     );
// }


export { Game };
