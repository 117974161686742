import React from 'react';
import CriteriumLogo from './CriteriumSG_onWhite.jpg';
import HackaTRonLogo from './hackaTRon_ColorLogo.jpg';
import ProtoJeuxLogo from './PJdotsSG.jpg';
import SELLogo from './SEL_LOGO_Slogan.jpg';
import XTRALudiqLogo from './XTRAludiq_ColorLogo.jpg';

var serviceList = [];
let title = "SEL - Séances d'Éducation Ludique";
serviceList.push(
    {
        ID: "1",
        Link: {
            web: "https://www.cajeclub.com/services-scolaires",
            title: <img src={SELLogo} alt={title} className="img-fluid mw-200" />,
            classes: "",
            icones: null,
            isInternal: false

        },
        Title : title,
        Description: "Ce projet consiste à réunir les professionnels offrant des services d’animations, de conférences et autres, centrés autour du jeu de société. Jumelant différents acteurs du domaine ludique en plusieurs séances présentielles et en visioconférences. Combinant les services de chacun en un projet uniquement disponible aux écoles primaires du Québec.",
        offers: ["Rencontres - personnalités passionnées", "Rencontres - auteurs et artisans", "Atelier de création - visioconférence", "Atelier de création - présentielle", "Jeux et animations - demie-journée", "Jeux et animations - journée"]

    }

);
title = "Critérium - Services conseils et de conceptions";
serviceList.push(
    {
        ID: "2",
        Link: {
            web: "https://www.cajeclub.com/services-aux-individus",
            title: <img src={CriteriumLogo} alt={title} className="img-fluid mw-200" />,
            classes: "",
            icones: null,
            isInternal: false

        },
        Title : title,
        Description: "Le <strong>Critérium</strong> permet de vivre l’expérience du test à l’interne, accompagné des membres du comité de gestion. ",
        offers: ["Évaluation de prototypes de jeux", "Phase de tests physiques", "Obtention de références/accompagnement", "Conseils créatifs/techniques", "Financement de projets/conceptions", "Services de ventes en ligne"]

    }

);
title = "hackaTRon - Concours scolaires en créations de jeux";
serviceList.push(
    {
        ID: "3",
        Link: {
            web: "https://www.cajeclub.com/services-scolaires#hackatron",
            title: <img src={HackaTRonLogo} alt={title} className="img-fluid mw-200" />,
            classes: "",
            icones: null,
            isInternal: false

        },
        Title : title,
        Logo: HackaTRonLogo,
        Description: "Ce concours est une activité ludique qui vise à aider les jeunes en ces exercices de qualités entrepreneuriales. Dans le cadre d’un travail d’équipe qui stimule la créativité, le but du projet est de développer le prototype d’un jeu de société collectivement. En s’accordant à certaines directives de créations; exploitant ainsi les bonnes idées, les forces et les capacités de chacun, tout en s’amusant.",
        offers: ["Concours scolaires"]

    }

);
title = "protojeux - Parcours de visibilité des prototypes";
serviceList.push(
    {
        ID: "4",
        Link: {
            web: "https://www.cajeclub.com/services-aux-individus#protojeux",
            title: <img src={ProtoJeuxLogo} alt={title} className="img-fluid mw-200" />,
            classes: "",
            icones: null,
            isInternal: false

        },
        Title : title,
        Logo: ProtoJeuxLogo,
        Description: "Les <strong>protojeux</strong> représentent une tâche importante dans le déroulement d’un projet de jeu. Les membres possèdent le pouvoir de décision sur l’avenir de leurs créations. Développant leurs prototypes selon les critères de marché provenant du domaine des jeux de sociétés. <br/> Un parcours sur trois rondes, en simultanées, pour sélectionner les prototypes qui seront présents à l’XTRA ludiq. Bénéficiez d’une visibilité auprès du public, tout en récoltant un remboursement lors d’acquisition de versions indépendantes de votre jeu par certains acteurs du domaine des jeux.",
        offers: ["Production des prototypes", "Parcours du protojeux", "Sélection des prototypes à l’XTRA ludiq"]

    }

);
title = "XTRA ludiq";
serviceList.push(
    {
        ID: "5",
        Link: {
            web: "https://www.cajeclub.com/evenements#xtra-ludiq",
            title: <img src={XTRALudiqLogo} alt={title} className="img-fluid mw-200" />,
            classes: "",
            icones: null,
            isInternal: false

        },
        Title : title,
        Logo: XTRALudiqLogo,
        Description: "Situé au parc de l’Île Saint-Quentin, à Trois-Rivières, <strong>XTRA ludiq</strong> vous offre deux salles de jeux vitrées avec vue sur le fleuve, des commanditaires sur place, des exposants créatifs, des artistes sur la scène extérieur, traiteur et casse-croûte… Plus de 300 jeux disponibles en une journée de jeux libres services, avec la présence d’animateurs pour vous expliquer les règles au besoin.",
        offers: ["Événementiel"]

    }

);

export { serviceList }