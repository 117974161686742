import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';


import App from './App.js';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId="221170734484-1lnl5rqdtbkgpch12ifs90cie09qcj3r.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);


