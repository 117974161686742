import axios from 'axios';
const baseURL = 'https://api.creabek.ca/api/';
async function checkAccountExist(email) {
    try {
        const params = new URLSearchParams({ 'email': email });
        var url = baseURL + 'users/exist'
        const { data: response } = await axios.get(url, { params });
        return response;
    } catch (error) {
        console.log(error);
    }
}

async function createAccount(email) {
    try {
        var url = baseURL + 'users/createAccount'
        await axios.post(url, { 'email':email });
    } catch (error) {
        console.log(error);
    }
}

async function authUser(email) {
    if (localStorage.getItem('currentUser') && !localStorage.getItem("currentUser").includes("undefined")) {
        return JSON.parse(localStorage.getItem('currentUser'));
    }
    try {
        const params = new URLSearchParams({ 'email': email });
        var url = baseURL + 'users/auth'
        await axios.get(url, { params }).then(data => localStorage.setItem('currentUser', JSON.stringify(data.data[0])));
    } catch (error) {
        console.log(error);
    }
}

async function loginUser(googleToken) {
    try {
        await axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleToken}`, {
                headers: {
                    Authorization: `Bearer ${googleToken}`,
                    Accept: 'application/json'
                }
            })
            .then(async (res) => {

                await Api.User.CheckAccountExist(res.data.email).then(async res2 => {
                    if (res2.exist === false) {
                        await Api.User.CreateAccount(res.data.email);
                    }
                    await Api.User.Auth(res.data.email);
                });
            })

    } catch (error) {
        console.log(error);
    }
}

async function changeVisibility(game) {
    try {
        game.Visibled = game.Visibled === 1 ? 0 : 1;
        var url = baseURL + 'games/changeVisibility'
        await axios.post(url, { 'GID':game.G_ID,'visible':game.Visibled});
    } catch (error) {
        console.log(error);
    }
}


async function addGame(gameData) {
    try {
        var url = baseURL + 'games/add'
        await axios.post(url, gameData);
            } catch (error) {
                
        console.log(error);
    }
}

export const Api = {

    Games: {
        List: async () => {
            try {
                var url = baseURL + 'games'
                const { data: response } = await axios.get(url);
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        Mechanics: async () => {
            try {
                var url = baseURL + 'games/mechanics'
                const { data: response } = await axios.get(url);
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        ChangeVisibility:changeVisibility,
        AddGame:addGame
    },
    Events: {
        List: async () => {
            try {
                var url = baseURL + 'events'
                const { data: response } = await axios.get(url);
                return response;
            } catch (error) {
                console.log(error);
            }
        },
    },

    User: {
        LogIn: loginUser,
        Auth: authUser,
        CheckAccountExist: checkAccountExist,
        CreateAccount:createAccount,
        MyGames: async () => {
            try {
                const params = new URLSearchParams({ 'email': JSON.parse(localStorage.getItem('currentUser')).email });
                var url = baseURL + 'users/games'
                const { data: response } = await axios.get(url, { params });
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        MyEvents: async () => {
            try {
                const params = new URLSearchParams({ 'email': JSON.parse(localStorage.getItem('currentUser')).email });
                var url = baseURL + 'users/events'
                const { data: response } = await axios.get(url, { params });
                return response;
            } catch (error) {
                console.log(error);
            }
        }


    }

}