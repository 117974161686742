import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from '@components'
import { Home, GameList, EventList, MyAccounts,RessourceList,AboutUs,AddGame } from '@views'

import './ressources/bootstrap-4.6.2-dist/css/bootstrap.css'
import './style.css'
// import './ressources/bootstrap-4.6.2-dist/js/bootstrap.js'

export default function App() {

  return (
    // ,
    // {
    //   "src": "logo192.png",
    //   "type": "image/png",
    //   "sizes": "192x192"
    // },
    // {
    //   "src": "logo512.png",
    //   "type": "image/png",
    //   "sizes": "512x512"
    // }
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="maPage" element={<MyAccounts />} />
          <Route path="protos">
            <Route path="" element={<GameList  />} />
            {/* <Route path=":viewMode" element={<GameList  />} /> */}
            <Route path="add" element={<AddGame  />} />
          </Route>
          <Route path="evenements" element={<EventList />} />
          <Route path="ressources" element={<RessourceList />} />
          <Route path="aPropos" element={<AboutUs />} />
          </Route>
      </Routes>
    </BrowserRouter> 
  );
}