import React from 'react';
import { Navigation } from '@utils';
import { CAJE } from './CAJE';



function RessourceList() {
    Navigation.SetCurrentLink('ressources');
    return (

        <section className="my-2" >
            <div className="container py-5">
                <div className="row">
                    <div className='col-sm-12'>
                        <h1>Ressources</h1>
                        <p>Explorez notre liste de ressources incontournables ! Des outils, des connaissances et des inspirations pour les créateurs de jeux. Plongez dans l'univers du jeu comme jamais auparavant !</p>

	    		<CAJE key="caje" />
                    </div>

                </div>

            </div>
        </section>



    );

}
export { RessourceList }
