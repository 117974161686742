import React from 'react';
import { CreabekLink } from '@components';
import { Navigation } from '@utils';

function AboutUs() {
  Navigation.SetCurrentLink('about');
  var linkHome = {
    web: "https://creabek.ca",
    title: "Créabek ",
    classes: "",
    icones: null,
    isInternal: false

};
var linkPhenix = {
  web: "https://phenix-games.com/",
  title: "Phénix Games ",
  classes: "",
  icones: null,
  isInternal: false

};
  return (

    <section className="my-2" >
    <div className="container py-5">
    <h1>Mission de Créabek</h1>
            <p>
            <strong>La plateforme <CreabekLink linkData={linkHome} /></strong>
            est conçu par <em><CreabekLink linkData={linkPhenix} /></em> et n'est aucunement à usage commercial. Son seul but est de fournir un endroit de partage et réseautage pour l'ensemble de la communauté de concepteurs de jeux de sociétés au Québec.</p>
        </div>
      </section>
   
  );
}
export { AboutUs };