import React, { useState, useEffect } from 'react';
import { Api, Navigation } from '@utils';
import { Game,PageTitle } from '@components'





function GameList({ myGames = false, homePage = false }) {

    const [gamesList, setGamesList] = useState([]);
    const [mechanicsList, setMechanicsList] = useState([]);

    useEffect(() => {
        if (homePage === false && myGames === false) {
            Navigation.SetCurrentLink('protos')
        }
        if (gamesList.length === 0) {
            var promise = myGames ? Api.User.MyGames() : Api.Games.List();
            promise.then(data => { homePage ? setGamesList(data.slice(0, 3)) : setGamesList(data); });
        }
        if (mechanicsList.length === 0) {

            Api.Games.Mechanics().then(data => setMechanicsList(data));
        }
    }
    )
    return <>
        {gamesList ? (
            <section className="my-2" >
                <div className="container py-5">
                    {myGames ?
                        (
                            <></>
                        ) : (<>
                            <PageTitle mainPage={!homePage} title="Prototypes de jeux de société : L'évolution de l'innovation ludique"/> 

                            <p>Explorez l'univers des prototypes de jeux de société, où les idées prennent vie et évoluent. Découvrez le processus créatif, les défis et les moments d'inspiration qui font naître les jeux de société.</p>
                        </>)
                    }
                    <ul className="list-group list-group-flush mt-3 mt-md-5 mb-2">
                        
                            {gamesList.map((g) => (
                                <Game  key={g.G_ID} game={g} myGame={myGames} mechanicsList={mechanicsList.filter(mech => mech.ID === g.G_ID)}></Game>
                            ))}
                        
                    </ul>
                    {homePage ?
                        (

                            <div className="row">
                                <div className="col-sm-12 text-center"><a href="/protos" className="btn btn-secondary mx-auto">Découvrez tous les prototypes</a></div></div>

                        ) : (
                            <></>
                        )
                    }
                </div>
            </section>
        ) : (
            <></>
        )}
    </>
}
export { GameList };
