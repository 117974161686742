import React from 'react';
import { GameList, EventList } from '@views';
import { Navigation } from '@utils';
import imageAccueil from './ck_img_intro.svg'

function Home() {
  Navigation.SetCurrentLink('home');
  return (
    <>
      <section className=" jumbotron header mt-5">
        <div className="container p-md-5 pt-sm-6">
          <div className="row d-flex align-items-center">
          <div className="col-md-7 order-md-2">
              <img src={imageAccueil} alt="Créabek, plateforme des créateurs du Québec" />
            </div>

            <div className="col-md-5 order-md-1">
              <h1>Explorez l'univers captivant de la conception ludique</h1>
              <p className="lead text-muted">Découvrez le monde fascinant des concepteurs et conceptrices de jeux de société ! Explorez et partagez votre passion pour la création ludique avec notre communauté dynamique. </p>

              <a href="/protos" className="btn btn-primary my-2">Découvrez la liste des prototypes </a>
              <a href="/evenements" className="btn btn-secondary my-2">Participez aux événements</a>

            </div>
            
          </div>
        </div>
      </section>
      <GameList homePage={true} />
      <EventList homePage={true} />
    </>
  );
}
export { Home };