import React from 'react';
import { CreabekLink } from '@components';

function LegalPopup() {

    var linkBBG = {
        web: "https://boardgamegeek.com/",
        title: "BGG",
        classes: "",
        icones: null,
        isInternal: false

    };
    return (

        <div >

            <h3>Contrat d'utilisation</h3>
            <p>
                Le présent contrat est faite entre vous (utilisateur) et Jeux phénix (Phénix Games)</p>
           
            <strong>Par la simple consultation du site, vous acceptez</strong> les présentes conditions.
            <p></p>
            <h4>Utilisation du service de création de compte</h4>
            <p>Tous les services offerts via la création d'un compte sur la plateforme sont <strong>GRATUIT</strong><br />
                Nous vous demandons un compte Google, car nous ne voulons pas enregistrer des informations sensibles sur nos serveurs.<br />
                La seule information que nous avons sur vous est votre adresse courrielle.<br />
                <strong>Cependant</strong> Phénix Games affiche différents services comme des événements ou des organismes ressources.<br />
                Ces derniers ont leur propre condition et peuvent offrir des services commerciaux indépendant des notres.
            </p>
            <h4>Propriété intellectuelle</h4>
            <p>La liste des mécaniques de jeux provient du site  <CreabekLink linkData={linkBBG} />. Certains éléments ont été rajouté par la communauté</p>
            <p>Tout contenu mutimédia et textes sur cette platforme <strong>concernant un prototype, un événement ou une ressource</strong> appartient à son auteur</p>
            <p>Les textes et contenus multimédia génériques sont la propriété de <em>Phénix Games</em></p>
            <p>En tant qu'utilisateur, vous avez droit d'accès à vos données en tout temps.<br />Envoyez un courriel à
                <a href="mailto:admin@phenix-games.com"> admin@phenix-games.com</a> pour apporter toutes les modifications à vos données.<br />
                Par mesure de sécurité nous vous demandons d'utiliser votre adresse Google enregistré à votre compte lors de vos communications.
            </p>
            <h4>Autorisation octroyée aux utilisateurs</h4>
            <p>Il est interdit d'utiliser tout contenu sur la plateforme <strong>sans en avoir eu le consentement clair et écrit</strong> de son auteur.</p>
            <h4>Limitation des responsabilités</h4>
            <p><em>Phénix Games</em> n'est aucunement responsable de tout contenu publié par ses utilisateurs ou par les liens externes de ces dits utilisateurs.<br />
                Nous nous engageons à faire une veille et filtrer tout contenu publié pour éviter le maximum de contenus non-adéquat.<br />
                Si votre contenu est refusé ou retiré par un administrateur de la plateforme, un courriel vous expliquant la raison vous sera transmis et vous serez en droit de contester cette décision.
            </p>
            <h4>Changements sans préavis</h4>
            <p>Les présentes conditions peuvent changer sans préavis.<br />
            Phénix Games s'engage à ne pas changer ses offres de services gratuitement pour aider la visibilité de la communauté.
            </p>
            <h4>Langue de traduction</h4>
            <p>Toute traduction des présentes conditions ne sont pas valables si elle ne sont pas explicitement fourni par Phénix Games</p>

        </div>

    );

}


export { LegalPopup };
