
import React from 'react';
import { GameList, EventList } from '@views';
import { User, Navigation } from '@utils';
import {Link } from "react-router-dom";

import { CreabekMailToLink, CreabekLink } from '@components';
import { Joystick } from 'react-bootstrap-icons';

function MyAccounts() {
  Navigation.SetCurrentLink('myPage');
  var linkDataGame = {
    web: User.CurrentUser().CG_Web,
    title: User.CurrentUser().CG_Name,
    classes: "",
    icones: null,
    isInternal: false

  }; var linkDataEvent = {
    web: User.CurrentUser().CE_Web,
    title: User.CurrentUser().CE_Name,
    classes: "",
    icones: null,
    isInternal: false

  };
  var cc = User.CurrentUser().email;
  var mailInfoGame = { subject: "Demande création compte prototypes", cc: cc, title: "Demander un compte «Concepteur»" };
  var mailInfoEvent = { subject: "Demande création compte événement", cc: cc, title: "Demander un compte «Événements»" };
  return (



    <section className="my-2" >
      <div className="container py-5">
        <div className="row mb-2 mb-md-4">
          <div className="col-sm-12">
          <h1>Mes comptes sur Créabek</h1>
        <div className="alert alert-info" role="alert">
          {/* Pour ajouter ou modifier un prototype ou un événement, simplement communiquer avec nous au <a href="mailto:admin@phenix-games.com" ><Joystick /> admin@phenix-games.com <Joystick /></a> */}
          Pour ajouter un événement ou modifier un prototype, simplement communiquer avec nous au <a href="mailto:admin@phenix-games.com" ><Joystick /> admin@phenix-games.com <Joystick /></a>
        </div>
          </div>

        </div>
        

        <div className="row">
            <div className="col-sm-12">
        {User.CurrentUser().CG_Name ? (
          <>

            <h2>Compte : Concepteur Prototypes (<CreabekLink linkData={linkDataGame} />)</h2>
           
            <Link reloadDocument={true} className="btn btn-primary my-2" to="/protos/add">Ajouter un jeu</Link>
            
            <p>Vous trouverez ci-bas la liste de vos prototype actif.</p>
            <div className="mt-n5">
            <GameList myGames={true} />
            </div>
            
          </>) : <div><h2>Aucun compte «Concepteur» pour le moment</h2>
          <br />
          <CreabekMailToLink mailInfo={mailInfoGame} />
          <br />

        </div>
        }
        {User.CurrentUser().CE_Name ? (
          <>
            <h2>Compte : Organisateur Événements (<CreabekLink linkData={linkDataEvent} />)</h2>
            <p>Vous trouverez ci-bas la liste des événements dont vous êtes un organisateur.</p>
            <EventList myEvents={true} />
          </>) :
          <div><h2>Aucun compte «Événements» pour le moment</h2>
            <br />
            <CreabekMailToLink mailInfo={mailInfoEvent} />
            <br />
          </div>}
      </div>
      </div>
      </div>
    </section>

  );
}


export { MyAccounts };
