import { googleLogout } from '@react-oauth/google';
import {Api} from '@utils'


async function login(googleToken)
{
    await Api.User.LogIn(googleToken);
    
}

export const User = {

    IsConnected:  () => {
       return localStorage.getItem('currentUser') && !localStorage.getItem("currentUser").includes("undefined");
    },
    CurrentUser: () => {
        return JSON.parse(localStorage.getItem('currentUser'));
    },
    LogIn: login,
    LogOut: () => {
        googleLogout();
        localStorage.removeItem("currentUser");
        localStorage.removeItem("user");
    }
}
