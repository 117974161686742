import React, { useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import { Api, Navigation } from '@utils';

function AddGame() {
    var gameData = {};
    const [html, setHtml] = useState('');
    const update = (name, e) => {
        gameData[name] =  e.target.value ;
      };

      function onChange(e) {
        setHtml(e.target.value);
      }
    Navigation.SetCurrentLink('addGame');
    const addGameDB = () => {
        gameData['email'] = JSON.parse(localStorage.getItem('currentUser')).email;
        gameData['description'] = html;
        Api.Games.AddGame(gameData);
      };
    return (
        <>
            <section className="my-2" >
                <div className="container py-5">
                    <form  onSubmit={addGameDB}>
                        <div className="form-row">
                            <div className="col-md-10 mb-2">
                                <label htmlFor="validationDefault01">Nom du jeux</label>
                                <input type="text" value={gameData.name} className="form-control" onChange={(e) => update("name", e)}  id="validationDefault01" placeholder="Nom" required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-10 mb-2">
                                <label htmlFor="validationDefault01">Description</label>
                                <Editor value={html} onChange={onChange}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-10 mb-2">
                                <label htmlFor="validationDefault03">Nombre de joueurs</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-4 mb-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend2">Min</span>
                                    </div>
                                    <input type="number" className="form-control" value={gameData.NBPlayersMin} onChange={(e) => update("NBPlayersMin", e)} id="validationDefaultUsername" placeholder="playersMin" aria-describedby="inputGroupPrepend2" required />
                                </div>

                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend2">Max (facultatif)</span>
                                    </div>
                                    <input type="number"  value={gameData.NBPlayersMax} onChange={(e) => update("NBPlayersMax", e)} className="form-control" id="validationDefaultUsername" placeholder="playersMax" aria-describedby="inputGroupPrepend2" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="validationDefault03">Temps moyen d'une partie (facultatif)</label>
                                <input type="text"  value={gameData.Duration} onChange={(e) => update("Duration", e)} className="form-control" id="validationDefault03" placeholder="30 minutes" />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label htmlFor="validationDefault04">Âge minimum</label>
                                <input type="number"  value={gameData.MinimumAge} onChange={(e) => update("MinimumAge", e)} className="form-control" id="validationDefault04" placeholder="ageMin" required />
                            </div>
                        </div>
                        <button className="btn btn-primary" type="submit">Ajouter</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export { AddGame };
