import React from 'react';
import { CaretRight, XCircle } from 'react-bootstrap-icons';
import { CreabekLink } from '@components';
import { serviceList } from './data'
import logoCAJE from './CAJE_SignGraph.jpg'
function CAJEServiceOffres({ offerList, startKey }) {
  return (
    <>
      {offerList.map((o) => (
        <div className="col mb-2" key={startKey + "-" + o}>
          <span className="badge">
            <CaretRight /> {o}
          </span>
        </div>
      ))}
    </>


  );
}

function CAJEService({ service }) {
  let Link = {
    web: service.Link.web,
    title: "Site internet ",
    classes: "btn btn-primary",
    icones: null,
    isInternal: false

  }
  return (

    <div className="modal fade" id={"RCS-" + service.ID} data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">

            <h5 className="modal-title" id="staticBackdropLabel">{service.Title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><XCircle /></span>
            </button>
          </div>
          <div className="modal-body py-4">
            <CreabekLink linkData={service.Link} />

            <div className="mx-3 mt-2 mb-4">
              <h3>Description</h3>
              <p dangerouslySetInnerHTML={{ __html: service.Description }} />

            </div>

            <div className="mx-3 mt-2 mb-4">

              <h4 className="mb-2">Offres</h4>
              <div className="row">
                <CAJEServiceOffres offerList={service.offers} startKey={"RCS-" + service.ID} />
              </div>
            </div>

            <div className='mx-3'>
              <CreabekLink linkData={Link} />
            </div>



          </div>
        </div>
      </div>
    </div>
  );
}

function CAJE() {

  return (
    <>
      <div className="card">
        <div className="card-body">

          <section className="my-2" >
            <img className="img-fluid mw-400" alt="logoCAJE" src={logoCAJE} />

            <h2 className='mb-2'>Coopérative de solidarité des Auteurs de Jeux d'Éditions.</h2>
            <p>
              La CAJE est une coopérative en développement offrant une aide et un soutient aux auteurs de jeux, illustrateurs, graphistes et autres artistes, ainsi qu'aux personnalités ludiques. La coopérative est un groupement de joueurs passionnés qui permet également aux auteurs de jeux de compléter des prototypes répondant à certaines normes du domaine ludique. D’offrir des outils pour promouvoir leur idée en un prototype jouable et leur permettre de tester leur jeu et règlements parmi les membres ou lors des “protojeux”, afin d'obtenir l'option d'envoyer leur prototype à une maison d'édition appropriée ou de présenter leur création lors d'événements ludiques.
            </p>

            {serviceList.map((s) => (
              <ul className="list-group list-group-flush" key={"#RCS-" + s.ID} >
                <li className="list-group-item bg-light">
                  <div className="d-md-flex justify-content-between align-items-center">
                    <div>{s.Title}</div>
                    <button type="button" className="btn btn-primary mt-1 mt-md-0" data-toggle="modal" data-target={"#RCS-" + s.ID}>
                      Voir les informations
                    </button>
                  </div>
                </li>
                <CAJEService service={s} />
              </ul>
            ))}


          </section>
        </div>
      </div>
    </>
  );
}


export { CAJE };