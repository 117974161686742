function setCurrentLink(newLink)
{

    localStorage.setItem('currentLink',newLink);
}

export const Navigation = {

    GetCurrentLink:  () => {
       return localStorage.getItem('currentLink') === null ? "home" : localStorage.getItem('currentLink');
    },
    SetCurrentLink:setCurrentLink
}