import React, { useState, useEffect } from 'react';
import { Api,Navigation } from '@utils';
import { Event,PageTitle } from '@components'

function EventList({ myEvents = false, homePage = false }) {
    const [eventsList, setEventsList] = useState([]);
    if(homePage === false && myEvents === false)
    {
        Navigation.SetCurrentLink('events');
    }
    useEffect(() => {
        if (eventsList.length === 0) {
            var promise = myEvents ? Api.User.MyEvents() : Api.Events.List();
            promise.then(data => { homePage ? setEventsList(data.slice(0, 2)) : setEventsList(data) });
        }
    }
    )
    return <>
        {eventsList ? (
            <section className="my-2" id="evenement">
                <div className="container py-5">

                    {myEvents ?
                        (
                            <></>
                        ) : (<>
                           <PageTitle mainPage={!homePage} title="Événements à venir"/> 
                            <p>Plongez dans des événements palpitants de rencontres passionnantes. Rejoignez-nous pour des moments de divertissement inoubliables, où joueurs de tous niveaux et passionnés se réunissent pour partager des expériences ludiques.</p>
                        </>)
                    }

                    <div className="row row-cols-1 row-cols-md-2 my-3 mt-md-5 evenement">
                        {eventsList.map((e) =>
                            <Event event={e} key={e.ID}/>
                        )}
                    </div>

                {homePage ?
            (
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <a href="/evenements" className="btn btn-secondary mx-auto">Voir tous les événements à venir</a>
                        </div>
                    </div>
            ) : (<>

            </>)
        }
                </div>
            </section>
        ) : (
            <></>
        )}
       
    </>
}

export { EventList };
