import React from 'react';
import { BoxArrowUpRight, GeoAltFill, Calendar, CalendarPlus } from 'react-bootstrap-icons';
import { MyDate, CreabekLink } from '@components';

function EventAdmin({ event }) {
    return (
        <>
            {/* <button type="button" class="bi bi-eye"  >

            </button> */}

        </>
    );
}
function Event({ event, myEvent = false }) {
    var getvalidDate = function (d) { return d.split('T')[0] }
    var linkData = {
        web: event.Web,
        title: "En savoir plus  ",
        classes: "card-link",
        icones: <BoxArrowUpRight />,
        isInternal: false

    };
    var today = getvalidDate(new Date().toJSON());
    var eventRunningNow = event.StartDate >= today && event.EndDate <= today;


    var registerStartDate = event.RegisterStartDate === null ? '9999-12-31T' : event.RegisterStartDate;
    var registerEndDate = event.RegisterEndDate === null ? '1970-01-01T' : event.RegisterEndDate;
    var eventRegisteringNow = getvalidDate(registerStartDate) <= today && getvalidDate(registerEndDate) >= today;
    var registerTitle = event.RegisterStartDate !== null || event.RegisterEndDate !== null ? "Inscriptions" : "";
    if (eventRegisteringNow) {
        registerTitle = <strong>Inscription en cours</strong>
    }

    return (
        <div className="col mb-4">
            <div className="card bg-light">
                <div className="card-body">

                    <h3>{event.E_Name}  {eventRunningNow ? <strong> - En cours</strong> : <></>}</h3>
                    <p><GeoAltFill />{event.Location}</p>
                    {registerTitle !== "" ? <p>{registerTitle} <br /><CalendarPlus /> {MyDate.ParseDate(event.RegisterStartDate, event.RegisterEndDate, false, ' au ')}</p> : <></>}
                    <p><Calendar /> {MyDate.ParseDate(event.StartDate, event.EndDate)}</p>
                    <CreabekLink linkData={linkData} />
                    {myEvent ? (<EventAdmin event={event} />) : <></>}
                </div>
            </div>
        </div>

    );
}




export { Event };
