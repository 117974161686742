
function ParseDate(timestampStart,timestampEnd, fullTime = false, separator=' - ')
{

   
    var formattedTimeStart = ParseSingleDate(timestampStart,fullTime);
    var formattedTimeEnd = ParseSingleDate(timestampEnd,fullTime);
   if(formattedTimeEnd !== "")
   {
    formattedTimeStart = formattedTimeStart + separator + formattedTimeEnd
   }
    return formattedTimeStart;

}
 
function ParseSingleDate(timestamp, fullTime = false)
{

    if(timestamp === null)
    {
        return "";

    }
    var date = new Date(new Date(timestamp).getTime() + (4*60*60*1000));

    var formattedTime = date.toLocaleDateString('fr-ca', { weekday:"long", year:"numeric", month:"long", day:"numeric"});
    if(fullTime)
    {
        var hour = date.getHours();
        var minutes = "0" + date.getMinutes();
        formattedTime = formattedTime + ' ' + hour + ':' + minutes;
    }
    return formattedTime;

}



export const MyDate = {

    ParseDate:ParseDate
}